<template>
	<div class="card card-flush pb-4">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Request Purchase Orders
			</div>
			<div style="width: 200px">

				<!-- <v-select
					filled
					v-model="currentStatus"
					:items="statusList"
					item-text="text"
					text-value="value"
					@change="changeStatus"
				></v-select> -->

				<div style="width: 200px">
					<v-select
						filled
						v-model="currentStatus"
						:items="statusList"
						item-text="text"
						text-value="value"
						@change="changeStatus"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-content style="padding: 0px" dense>
									<v-list-item-title
										class="d-flex justify-space-between"
										style="padding: 0px; font-weight: 500 !important"
										><div>
											{{ data.item.text }}
										</div>

										<div>
											<v-chip small text :color="getStatusColor(data.item.value)" class="mx-2 white--text">{{
												data.item.count
											}}</v-chip>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-select>
				</div>
			</div>
		</div>
		<div class="taskListing mx-3">
			<table v-if="!pageLoading" class="table table-row-dashed align-middle my-0">
				<thead style="background-color: #f7fafc">
					<tr>
						<th>RPO #</th>
						<th>Details</th>
						<th>Customer</th>
                        <th>Supplier</th>
						<!-- <th >Contact Person</th>
						<th >Billing Address</th> -->
						<th>Project</th>
						<th>Created AT</th>
					</tr>
				</thead>
				<tbody v-if="purchase_order.length">
					<tr
						@click="goToPurchaseOrder(row.uuid)"
						class="cursor-pointer"
						v-for="(row, index) in purchase_order"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										tooltip
										tooltip-text="purchase-order #"
										small
										:text="row.barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<div>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												v-bind="attrs"
												v-on="on"
												outlined
												label
												tooltip
												style="text-transform: uppercase"
												class="mt-1"
												:color="getStatusColor(row.status_value)"
											>
												<span v-if="row.status_value === 'sent_for_approval'"> Sent for Approval</span>
												<span v-else>{{ row.status_value }}</span>
											</v-chip>
										</template>
										<span> Status </span>
									</v-tooltip>
								</div>
							</div>
						</td>
						<!-- <td>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed fab dark x-small color="green" v-bind="attrs" v-on="on" class="mr-3">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit Project</span>
							</v-tooltip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on" class="mr-3">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete Project</span>
							</v-tooltip>
						</td> -->
						<td class="td_column cursor-default simple-table-td">
							<div>
								<span class="fw-500">Ref : </span>
								<ShowValue :object="row" object-key="reference_number" label="Reference number"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Amount : </span>
								<ShowPrice :object="row" object-key="sub_total" label="amount"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Delivery Date : </span>
								<template v-if="row.date">
									{{ formatDate(row.date) }}
								</template>
								<template v-else>
									<em class="text-muted">no date</em>
								</template>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer company name"
									:object="row"
									object-key="customer_company_name"
									label="customer company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer name"
									:object="row"
									object-key="customer_name"
									label="Customer Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="customer email"
									:object="row"
									object-key="customer_email"
									label="customer email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="Customer Phone"
									:object="row"
									object-key="customer_phone"
									label="Customer Phone"
								>
								</ShowValue>
							</div>
						</td>

                        <td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="supplier company name"
									:object="row"
									object-key="supplier_company_name"
									label="Supplier company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="supplier name"
									:object="row"
									object-key="supplier_name"
									label="Supplier Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="supplier email"
									:object="row"
									object-key="supplier_email"
									label="Supplier email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="Supplier Phone"
									:object="row"
									object-key="supplier_phone"
									label="Supplier Phone"
								>
								</ShowValue>
							</div>
						</td>

						<td>
							<div>
								<span class="fw-500">Name : </span>
								<ShowValue :object="row" object-key="project_name" label="title"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Price : </span>
								<ShowPrice
									class="fw-600"
									:object="row"
									object-key="project_price"
									label="price"
								></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Start : </span>
								<template v-if="row.project_start_date">
									{{ formatDate(row.project_start_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">End : </span>
								<template v-if="row.project_end_date">
									{{ formatDate(row.project_end_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">Status : </span>
								<span v-if="row.project_status_value">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:color="getStatusColor(row.project_status_value || 'draft')"
												label
												outlined
												v-if="row.project_status_value"
												small
												v-on="on"
												v-bind="attrs"
												class="white--text"
											>
												<span class="text-uppercase">
													{{ row.project_status_value }}
												</span>
											</v-chip>
										</template>
										<span>Project Status</span>
									</v-tooltip>
								</span>
								<span v-else>
									<em class="text-muted fw-400">No status</em>
								</span>
							</div>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.created_by"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no purchase order at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
			<v-row class="my-2 d-flex justify-end">
				<v-col md="6" class="text-right">
					<v-pagination
						v-if="purchase_order.length"
						color="blue darken-4"
						v-model="currentPage"
						:length="totalPage"
						total-visible="7"
						v-on:input="getProjectList()"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
// import Status from "@/view/components/Status";
// import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import { getAllRPO } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";
import { SET_ERROR } from "@/core/services/store/common.module";

import Chip from "@/view/components/Chip";
export default {
	name: "purchase-order",
	components: {
		ShowValue,
		Chip,
		// Status,
		ShowPrice,
		TableDateTime,
		// ShowTextAreaValue,
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			purchase_order: [],
			currentStatus: "all",
			allStatusList:[],
			currentPage: 1,
			pageLoading: false,
			totalPage: null,
			statusList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "Draft",
					value: "draft",
				},
				{
					text: "Approved",
					value: "approved",
				},
				// {
				// 	text: "Cancelled",
				// 	value: "cancelled",
				// },
				{
					text: "Rejected",
					value: "rejected",
				},
				{
					text: "Pending",
					value: "pending_for_approved",
				},
			],
		};
	},
	computed: {
		priorityText: function () {
			return function name(row) {
				return row.priority === 1 ? "low" : row.priority === 2 ? "medium" : "high";
			};
		},
		priorityColor: function () {
			return function name(row) {
				return row.priority === 1
					? "grey--text lighten-1"
					: row.priority === 2
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusColor: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "green--text"
					: row.status_value === "in-progress"
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusBorder: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "border:1px solid #4CAF50"
					: row.status_value === "in-progress"
					? "border:1px solid #2196F3"
					: "border:1px solid #F44336";
			};
		},
	},
	methods: {
		goToPurchaseOrder(uuid) {
			this.$router.push({
				path: `/purchase-order/${uuid}`,
			});
		},
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		changeStatus(item) {
			this.currentStatus = item;
			this.getProjectList();
		},
		async getProjectList() {
			const _this = this;
			try {
				this.pageLoading = true;
				const { tbody, total_page,status } = await getAllRPO({
					page: this.currentPage,
					per_page: 5,
					status: this.currentStatus,
				});
				_this.purchase_order = [...tbody];
				this.allStatusList = [...status];
				_this.totalPage = total_page;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.getProjectList();
		
		this.statusList = this.allStatusList .map((ele) => {
			if (ele.value == "all") {
				ele.count =  this.dashboardDetail.all_rpo_counts;
			} else if (ele.value == "rejected") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "approved") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "draft") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "pending_for_approved") {
				ele.count = ele.quotation_status_count;
			}
			return ele;
		});
	},
};
</script>
