<template>
	<div class="card card-flush pb-4">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Quotations
			</div>
			<div style="width: 400px" class="d-flex">
				<AutoCompleteInput
					hide-details
					:items="manageSalesList"
					:disabled="pageLoading"
					:loading="pageLoading"
					id="sales-person"
					placeholder="Staff Person"
					v-model="currentSalePerson"
					@change="getSummaryQuotationData"
					style="margin-top: 1px !important;"
					class="mr-1"
				></AutoCompleteInput>
				<v-select
					filled
					v-model="summaryStatus"
					:items="timeDurationList"
					item-text="text"
					text-value="value"
					@change="getSummaryQuotationData"
				></v-select>
			</div>
			
		</div>
		<div>
			<v-col md="12">			
				<v-row>
					<v-col md="12" class="d-flex justify-space-between">
						
						<v-col md="3" style="width:25%;margin-right:5px;" @click="changeStatus('open')" class="box-shadow1 cursor-pointer" :class="getStatusColor('all')">
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold">Open Quotation</span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ getSummaryQuotation.quotations_summary.open_quotations_count }}
									</div>
									<div>{{ getSummaryQuotation.quotations_summary.open_quotations_total_count }}</div>
									<!-- <v-icon size="45" color="white">mdi-account-supervisor</v-icon> -->
									<!-- <inline-svg
										style="height: 50px; width: 50px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/task.svg')"
									/> -->
								</div>
							</div>
						</v-col>
						<v-col md="3" style="width:25%;margin-right:5px;" @click="changeStatus('closed')" class="box-shadow1 cursor-pointer" :class="getStatusColor('completed')">
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> Closed Quotation </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ getSummaryQuotation.quotations_summary.closed_quotations_count }}
									</div>
									<div>{{ getSummaryQuotation.quotations_summary.closed_quotations_total_count }}</div>
									<!-- <inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/completed.svg')"
									/> -->
								</div>
							</div>
						</v-col>
						<v-col md="3" style="width:25%;margin-right:5px;" @click="changeStatus('approved')" class="box-shadow1 cursor-pointer" :class="getStatusColor('in-progress')">
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold">Approved Quotation</span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ getSummaryQuotation.quotations_summary.approved_quotations_count }}
									</div>
									<div>{{ getSummaryQuotation.quotations_summary.approved_quotations_total_count }}</div>

									<!-- <inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/In-progress.svg')"
									/> -->
								</div>
							</div>
						</v-col>
						<v-col md="3" style="width:25%;margin-right:5px;" @click="changeStatus('due_15_days')" class="box-shadow1 cursor-pointer" :class="getStatusColor('pending')">
							<div class="p-1 text-white">
								<div class="d-flex justify-space-between">
									<span class="font-level-5-bold"> Expired in 15 days </span>
								</div>
								<div class="d-flex align-items-center justify-space-between mt-4">
									<div class="dashboard-summary-heading">
										{{ getSummaryQuotation.quotations_summary.due_quotations_count }}
									</div>
									<div>{{ getSummaryQuotation.quotations_summary.due_quotations_total_count }}</div>

									<!-- <inline-svg
										style="height: 55px; width: 55px"
										fill="#ffff"
										:src="$assetURL('media/custom-svg/pending.svg')"
									/> -->
								</div>
							</div>
						</v-col>
						
					</v-col>
				</v-row>
			</v-col>
		</div>
		<div style="width: 100%" class="d-flex justify-end px-4 mt-4">
			<div style="width: 200px">
				<v-select
						filled
						v-model="currentStatus"
						:items="statusList"
						item-text="text"
						text-value="value"
						@change="changeStatus"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-content style="padding: 0px" dense>
									<v-list-item-title
										class="d-flex justify-space-between"
										style="padding: 0px; font-weight: 500 !important"
										><div>
											{{ data.item.text }}
										</div>

										<div>
											<v-chip small text :color="getStatusColor(data.item.value)" class="mx-2 white--text">{{
												data.item.count
											}}</v-chip>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
				</v-select>
			</div>
		</div>
		<div v-if="!pageLoading" class="taskListing mx-3">
			<table class="table table-row-dashed align-middle my-0">
				<thead style="background-color: #f7fafc">
					<tr>
						<th class="">#</th>
						<!-- <th class="">Action</th> -->
						<th class="" width="120px">Quotation #</th>
						<th class="">Details</th>
						<th class="">Customer</th>
						<!-- <th class="">Contact Person</th>
						<th class="">Billing Address</th> -->
						<th class="">Project</th>
						<th class="">Created AT</th>
					</tr>
				</thead>
				<tbody v-if="quotationList.length">
					<tr
						class="cursor-pointer"
						v-for="(row, index) in quotationList"
						:key="index"
						@click="goToQuotation(row.uuid)"
					>
						<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<td width="50px" class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										tooltip
										tooltip-text="quotation #"
										small
										:text="row.barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<Chip
									class="mt-1"
									tooltip
									tooltip-text="status"
									small
									:text="row.status_value"
									:color="getStatusColor(row.status_value)"
								></Chip>
							</div>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div>
								<span class="fw-500">Tittle : </span>
								<ShowValue :object="row" object-key="title" label="title"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Reference : </span>
								<ShowValue :object="row" object-key="reference" label="Reference"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Prepared By : </span>
								<ShowValue :object="row" object-key="prepared_by" label="Prepared By"></ShowValue>
							</div>

							<div>
								<span class="fw-500">Sales Person : </span>
								<ShowValue :object="row" object-key="sales_person" label="Sale Person"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Amount : </span>
								<ShowPrice :object="row" object-key="total" label="Amount"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Expires On : </span>
								<ShowValue :object="row" object-key="valid_till" label="Valid till"></ShowValue>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer company name"
									:object="row"
									object-key="customer_company_name"
									label="customer company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer name"
									:object="row"
									object-key="customer_name"
									label="Customer Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="customer email"
									:object="row"
									object-key="customer_email"
									label="customer email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="Customer Phone"
									:object="row"
									object-key="customer_phone"
									label="Customer Phone"
								>
								</ShowValue>
							</div>
						</td>
						<td>
							<div>
								<span class="fw-500">Name : </span>
								<ShowValue :object="row" object-key="project_name" label="title"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Price : </span>
								<ShowPrice
									class="fw-600"
									:object="row"
									object-key="project_price"
									label="price"
								></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Start : </span>
								<template v-if="row.project_start_date">
									{{ formatDate(row.project_start_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">End : </span>
								<template v-if="row.project_end_date">
									{{ formatDate(row.project_end_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">Status : </span>
								<span v-if="row.project_status_value">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:color="getStatusColor(row.project_status_value || 'draft')"
												label
												outlined
												v-if="row.project_status_value"
												small
												v-on="on"
												v-bind="attrs"
												class="white--text"
											>
												<span class="text-uppercase">
													{{ row.project_status_value }}
												</span>
											</v-chip>
										</template>
										<span>Project Status</span>
									</v-tooltip>
								</span>
								<span v-else>
									<em class="text-muted fw-400">No status</em>
								</span>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.created_by"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no quotation at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<v-row class="my-2 d-flex justify-end">
				<v-col md="6" class="text-right">
					<v-pagination
						v-if="quotationList.length"
						color="blue darken-4"
						v-model="currentPage"
						:length="totalPage"
						total-visible="7"
						v-on:input="getProjectList()"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else class="mx-20 my-40 text-center h-100">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import { SET_ERROR } from "@/core/services/store/common.module";
import { getAllQuotation } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";
import Chip from "@/view/components/Chip";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ApiService from "@/core/services/api.service";

export default {
	name: "quotation-stats",
	components: {
		ShowValue,
		Chip,
		// Status,
		ShowPrice,
		TableDateTime,
		AutoCompleteInput,
		// ShowTextAreaValue,
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
		getSummaryQuotation: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			pageLoading: false,
			quotationList: [],
			allStatusList:[],
			summaryStatus: "all",
			currentStatus: "all",
			currentPage: 1,
			currentSalePerson :0,
			manageSalesList:[],
			totalPage: null,
			timeDurationList: [
				{
					text: "All",
					value: "all",
				},
				// {
				// 	text: "This Week",
				// 	value: "week",
				// },
				{
					text: "This Month",
					value: "month",
				},
				{
					text: "Last 3 Month",
					value: "last-3month",
				},
				{
					text: "Last 6 Month",
					value: "last-6month",
				},
			],
			statusList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "Draft",
					value: "draft",
				},
				{
					text: "Approved",
					value: "approved",
				},
				{
					text: "Rejected",
					value: "rejected",
				},
				{
					text: "Sent For Approval",
					value: "sent_for_approval",
				},
				{
					text: "Closed",
					value: "closed",
				},
			],
		};
	},
	computed: {
		...mapGetters(["localDB"]),
		priorityText: function () {
			return function name(row) {
				return row.priority === 1 ? "low" : row.priority === 2 ? "medium" : "high";
			};
		},
		priorityColor: function () {
			return function name(row) {
				return row.priority === 1
					? "grey--text lighten-1"
					: row.priority === 2
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusColor: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "green--text"
					: row.status_value === "in-progress"
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusBorder: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "border:1px solid #4CAF50"
					: row.status_value === "in-progress"
					? "border:1px solid #2196F3"
					: "border:1px solid #F44336";
			};
		},
	},
	methods: {
		getSummaryQuotationData(){
			let currentSalePerson = this.currentSalePerson;
			let summaryStatus=this.summaryStatus;

			ApiService.get(`dashboard/option/quotation-summary?sales_person=${currentSalePerson}&summary_status=${summaryStatus}`)
			.then(({ data }) => {
				this.getSummaryQuotation = data;
			})
			.catch(({ response }) => {
				console.error({ response });
			}).finally;
		},
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		goToQuotation(uuid) {
			this.$router.push({
				path: `/quotation/${uuid}`,
			});
		},
		changeStatus(item) {
			this.currentStatus = item;
			this.getProjectList();
		},
		async getProjectList() {
			// 	ApiService.get(`dashboard/all`)
			// 	.then(({ data }) => {
			// 		this.dashboardDetail = data;
			// 		// console.log(this.dashboardDetail);
			// 	})
			// 	.catch(({ response }) => {
			// 		console.error({ response });
			// 	}).finally;
			// {
			// }
			try {
				this.pageLoading = true;
				const { tbody, total_page,status } = await getAllQuotation({
					page: this.currentPage,
					per_page: 5,
					status: this.currentStatus,
					
				});
				this.quotationList = [...tbody];
				this.allStatusList = [...status];
				this.totalPage = total_page;
				// _this.$store.commit(SET_MESSAGE, [{ model: true, message: "Success ! Task has been ." }]);
			} catch (error) {
				// console.log(object);
				this.$store.commit(SET_ERROR, [{ model: true, message: "something went wrong!" }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.getProjectList();
		this.getSummaryQuotationData();
		this.statusList = this.allStatusList .map((ele) => {
			
			if (ele.value == "all") {
				ele.count =  this.dashboardDetail.all_quotation_counts;
			} else if (ele.value == "rejected") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "approved") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "draft") {
				ele.count = ele.quotation_status_count;
			} else if (ele.value == "sent_for_approval") {
				ele.count = ele.quotation_status_count;
			}else if (ele.value == "cancelled") {
				ele.count = ele.quotation_status_count;
			}
			else if (ele.value == "closed") {
				ele.count = ele.quotation_status_count;
			}
			return ele;
		});

		this.manageSalesList = this.localDB("sales_person", []);
		//console.log(this.manageSalesList);
		
	},

};
</script>
