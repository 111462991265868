<template>
	<div class="card card-flush pb-4">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Project Transfers
			</div>
			<div style="width: 200px">
				
				<div style="width: 200px">
					<v-select
						filled
						v-model="currentStatus"
						:items="statusList"
						item-text="text"
						text-value="value"
						@change="changeStatus"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-content style="padding: 0px" dense>
									<v-list-item-title
										class="d-flex justify-space-between"
										style="padding: 0px; font-weight: 500 !important"
										><div>
											{{ data.item.text }}
										</div>

										<div>
											<v-chip small text :color="getStatusColor(data.item.value)" class="mx-2 white--text">{{
												data.item.count
											}}</v-chip>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-select>
				</div>
			</div>
		</div>
		<div class="taskListing mx-3">
			<table v-if="!pageLoading" class="table table-row-dashed align-middle my-0">
				<thead style="background-color: #f7fafc">
					<tr>
						<th class="">#</th>
						<!-- <th class="">Action</th> -->
						<th class="" width="170px">Project Transfer #</th>
						<th class="">Project From</th>
						<th class="">Project To</th>
						<th class="">Remark</th>
						<!-- <th class="">Billing Address</th>
						<th width="120" class="">Service Address</th> -->
						<th class="">Created AT</th>
						<!-- <th max-width="150px" class="">Tags</th> -->
					</tr>
				</thead>
				<tbody v-if="projectList.length">
					<tr
						@click="goToProject(row.uuid)"
						class="cursor-pointer"
						v-for="(row, index) in projectList"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<td width="170px" class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										small
										tooltip
										tooltip-text="project #"
										:text="row.transfer_projects_barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<Chip
									class="mt-1"
									tooltip
									tooltip-text="status"
									:text="row.transfer_projects_status"
									:color="getStatusColor(row.transfer_projects_status)"
								></Chip>
							</div>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div>
                                
                                    <Chip
										small
										tooltip
										tooltip-text="project #"
										:text="row.transfer_form_barcode"
										color="blue darken-4"
									></Chip><br>
                                    <ShowValue :object="row" object-key="transfer_from" label="transfer_from" class="mt-3"></ShowValue>
								
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							    <Chip
										small
										tooltip
										tooltip-text="project #"
										:text="row.transfer_to_barcode"
										color="blue darken-4"
									></Chip><br>
								<ShowValue :object="row" object-key="transfer_to" label="transfer_to" class="mt-3"></ShowValue>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<ShowValue :object="row" object-key="transfer_projects_remark" label="transfer_projects_remark"></ShowValue>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.created_by"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no project transfers at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
			<v-row class="my-2 d-flex justify-end" v-if="projectList.length">
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="currentPage"
						:length="totalPage"
						total-visible="7"
						v-on:input="getProjectList()"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import ShowValue from "@/view/components/ShowValue.vue";
//import ShowPrice from "@/view/components/ShowPrice.vue";
// import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { getProjectTransfer } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";
import Chip from "@/view/components/Chip";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "task-stats",
	components: {
		ShowValue,
		Chip,
		// Status,
		//ShowPrice,
		TableDateTime,
		// ShowTextAreaValue,
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			projectList: [],
			currentStatus: "all",
			currentPage: 1,
			totalPage: null,
			allStatusList:[],
			pageLoading: false,
			// priorityList: [],
			statusList: [
				{
					text: "All transfered projects",
					value: "all",
				},
				{
					text: "Draft",
					value: "draft",
				},
				{
					text: "Approved",
					value: "approved",
				},
				{
					text: "Rejected",
					value: "rejected",
				},
                {
					text: "Sent for approval",
					value: "sent_for_approval",
				},
			],
		};
	},

	methods: {
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		changeStatus(item) {
			this.currentStatus = item;
			this.getProjectList();
		},
		async getProjectList() {
			const _this = this;
			try {
				_this.pageLoading = true;
				const { tbody, total_page,status } = await getProjectTransfer({
					page: _this.currentPage,
					per_page: 5,
					status: _this.currentStatus,
				});
				_this.projectList = [...tbody];
				this.allStatusList = [...status];
				_this.totalPage = total_page;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		goToProject(uuid) {
			this.$router.push({
				path: `/project-transfer/${uuid}`,
			});
		},
	},
	async mounted() {
		
		await this.getProjectList();
		this.statusList = this.allStatusList .map((ele) => {
			
			if (ele.value == "all") {
				ele.count =  this.dashboardDetail.all_project_transfer_counts;
			} else if (ele.value == "approved") {
				ele.count = ele.transfer_project_status_count;
			} else if (ele.value == "sent_for_approval") {
				ele.count = ele.transfer_project_status_count;
			} else if (ele.value == "draft") {
				ele.count = ele.transfer_project_status_count;
			} else if (ele.value == "rejected") {
				ele.count = ele.transfer_project_status_count;
			}
			return ele;
		});
	},
};
</script>
