<template>
	<div class="card card-flush pb-4 mr-2" style="min-height: 100%">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d- align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Tasks
				<span>({{ allTaskCount }})</span>
			</div>
			<div style="width: 150px">
				<v-select
					filled
					v-model="currentStatus"
					:items="timeDurationList"
					item-text="text"
					text-value="value"
					@change="changePurchaseOrderDetail"
				></v-select>
			</div>
		</div>
		<div class="mx-4 py-2" v-if="allTaskCount > 0">
			<div id="chart">
				<apexchart type="donut" width="480" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>
		<div v-else style="min-height: 350px" class="d-flex justify-center align-center">
			<p class="m-0 row-not-d text-center py-3">
				<img
					style="width: 45px; margin-right: 4px"
					:src="$assetURL('media/error/empty.png')"
					class="row-not-found-image"
				/>
				Uhh... There are no task at the moment.
			</p>
		</div>
	</div>
</template>

<script>
import { SET_ERROR } from "@/core/services/store/common.module";
import { changeDashboardDetailStatus } from "@/core/lib/common.lib";
export default {
	name: "task-stats",
	data() {
		return {
			currentStatus: "all",
			allTaskCount: null,
			timeDurationList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "This Week",
					value: "week",
				},
				{
					text: "This Month",
					value: "month",
				},
				{
					text: "Last 3 Month",
					value: "last-3month",
				},
			],
			series: [],
			dataLabels: {
				formatter(val) {
					return [Math.floor(val.toFixed(1)) + "%"];
				},
			},
			chartOptions: {
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -10,
						},
						formatter: function (val) {
							console.log("val", val);
							return val + "%";
						},
					},
				},
				stroke: {
					offsetX: 18,
					show: true,
					// colors: ["#2196F3", "#4CAF50", "#F44336", "#FF1744", "#EC407A", "#F57C00"],

					colors: ["#4CAF50", "#FF9800", "#F44336"],
					width: 8,
					curve: "smooth",
					dashArray: 1,
				},
				chart: {
					width: 480,
					type: "donut",
				},
				colors: ["#4CAF50", "#FF9800", "#F44336"],
				labels: ["Completed", "In Progress", "Pending"],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
			},
		};
	},
	methods: {
		async changePurchaseOrderDetail() {
			try {
				const query = {
					filter: this.currentStatus,
				};
				this.pageLoading = true;
				const { task_analysis_graph } = await changeDashboardDetailStatus("tasks", query);
				this.allTaskCount = task_analysis_graph?.all_task_count;
				this.series = [
					task_analysis_graph.completed_task_count,
					task_analysis_graph.inprogress_task_count,
					task_analysis_graph.pending_task_count,
				];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		this.series = [
			this.dashboardDetail.task_analysis_graph.completed_task_count,
			this.dashboardDetail.task_analysis_graph.inprogress_task_count,
			this.dashboardDetail.task_analysis_graph.pending_task_count,
		];
		this.allTaskCount = this.dashboardDetail?.all_task_counts;
		this.chartOptions = {
			plotOptions: {
				type: "pie",
				width: "350px",
			},
			tooltip: {
				enabled: false,
			},
			legend: {
				show: true,
				formatter: function (seriesName, opts) {
					return [seriesName, ": ", opts.w.globals.series[opts.seriesIndex]];
				},
			},
			chart: {
				width: 450,
				type: "donut",
			},
			dataLabels: {
				formatter(val) {
					if ([10, 20, 30, 40, 50, 60, 70, 80, 90, 100].includes(val)) {
						return [Math.round(val) + "%"];
					} else {
						return [val.toFixed(1) + "%"];
					}
				},
			},
			colors: ["#4CAF50", "#FF9800", "#F44336"],
			labels: ["Completed", "In Progress", "Pending"],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
		};
	},
};
</script>
